/*
 * 업무구분: 계약
 * 화 면 명: MSPPM902D
 * 화면설명: 보유계약-피보험자기준
 * 작 성 일: 2023.09.06
 * 작 성 자: sh.park
 */
<template>
  <ur-page-container class="msp" title="" :show-title="false" :topButton="false" @on-scroll-bottom="fn_LoadingDataCall">
    <ur-box-container v-if="pMRetContVO.length > 0" alignV="start" componentid="" direction="column" class="ns-check-list mb20">
      <mo-list :list-data="pMRetContVO">
        <template #list-item="{item, index}">
          <mo-list-item :key="compKey">
            <div class="list-item__contents w100" @click="fn_MoveMSPPM931M(item)">
              <!-- :: 계약 정보 :: -->
              <div class="list-item__contents__title w100">
                <span class="name txtSkip-inline">{{ item.prdtNm }}</span>
                <mo-badge class="badge-sample-badge sm" :class="item.ukepStatCdClassNm" text="정상" shape="status">{{ fn_GetUkepStatCdNm(item) }}</mo-badge>
              </div>
              <!-- 금액 -->
              <div class="list-item__contents__info">
                <div class="info_box">
                  <span class="fs16rem">
                    <strong class="pm-txt-blue">{{ $commonUtil.numberWithCommas(item.smtotPrmAm) }}원 </strong>
                    <span class="crTy-bk7">(계약일 : {{$commonUtil.dateDashFormat(item.contYmd)}})</span>
                  </span>
                </div>
              </div>
              <!-- 피보험자명 / 백지여부 / 생년월일 / 계약자명 -->
              <div class="list-item__contents__info">
                <span class="crTy-bk1" style="position: relative;">
                  <!-- 고객카드 없음 24.01.17 추가 -->
                  <span class="crTy-bk1 lnkTy1" :class="$bizUtil.isEmpty(item.insrdAgtFileId.trim()) ? 'need-cs-card' : ''"
                         @click.stop="fn_OpenInsrdCustInfoBs(item)">
                    (피) {{ item.insrdCustNm }}
                  </span>

                  <div v-if="item.showInsrdCustTooltip" class="chat-bubble type-M1 blu2" style="bottom: -85px; left: auto;">
                    <div class="exp">고객카드가 없는 고객이네요.<br />이름을 눌러 고객카드를 생성해보세요.<br />(개인고객만 생성 가능)</div>
                    <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click.stop="fn_CloseNoCardTooltip('showInsrdCustTooltip', index)" />
                  </div>
                </span><em>|</em>
                <span class="crTy-bk1" v-if="!$bizUtil.isEmpty(item.blkCustYn)">{{ item.blkCustYn !== 'Y' ? '보유' : '백지' }}</span><em>|</em>
                <span class="crTy-bk1">{{ $commonUtil.dateDashFormat(item.mkInsrdDob) }}</span><em>|</em>
                <span class="crTy-bk1" style="position: relative;">
                  <!-- 고객카드 없음 24.01.17 추가 -->
                  <span class="crTy-bk1 lnkTy1" :class="$bizUtil.isEmpty(item.conrtAgtFileId.trim()) ? 'need-cs-card' : ''"
                        @click.stop="fn_OpenCustInfoBs(item)">
                    (계) {{ item.contrCustNm }}
                  </span>

                  <div v-if="item.showContrCustTooltip" class="chat-bubble type-M1 blu2" style="bottom: -85px; left: auto;">
                    <div class="exp">고객카드가 없는 고객이네요.<br />이름을 눌러 고객카드를 생성해보세요.<br />(개인고객만 생성 가능)</div>
                    <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click.stop="fn_CloseNoCardTooltip('showContrCustTooltip', index)" />
                  </div>
                </span>
              </div>
            </div>
          </mo-list-item>
        </template>
      </mo-list>  
    </ur-box-container>

    <!-- NoData 영역  -->
    <ur-box-container v-else alignV="start" componentid="ur_box_container_004" direction="column" class="ns-contract-list no-data">
      <mo-list-item>
        <div class="list-item__contents">
          <div class="list-item__contents__info">
            <span>데이터가 없습니다.</span>
          </div>
        </div>
      </mo-list-item>
    </ur-box-container>
  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  // 현재 화면명
  name: 'MSPPM902D',
  // 현재 화면 ID
  screenId: 'MSPPM902D',
  props: {
    pMRetContVO: {
      type: Array,
      default: []
    }
  },

  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
  },
  mounted() {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)
  },

  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      lv_UserInfo: {}, // 사용자 정보
      compKey: 0
    };
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /**
     * @description 계약자명을 클릭할 때 nameUI를 호출한다.
     * @param       {Object}  item 선택된 보유계약 데이터 
     */
    async fn_OpenCustInfoBs (item) {
      const lv_Vm = this

      let nmUiObject = {}
      let prcContNo = ''

      // 계약번호 복호화 
      if(!this.$bizUtil.isEmpty(item.contNoEnc)) {
        prcContNo = await this.$commonUtil.getDecryptCall(this, [item.contNoEnc]) 
        prcContNo = prcContNo[0]
      }

      nmUiObject = {
        cnsltNo : item.clmnyCnsltNo,  // 담당 컨설턴트
        chnlCustId : item.conrtAgtFileId,    // 고객채널 ID
        parentId   : 'MSPPM902M', // 화면ID+
        contNo : prcContNo, // 계약번호
        contrInsrdSameCd : item.contrInsrdSameCd, // '01': 계피 동일, '02': 계피 상이
        custNm : item.contrCustNm, // 계약자 명
        custId : item.contrCustBpId, // 계약자 CustId
        sxdsCdNm : item.contrSexCd, // 계약자 성별코드
        sxdsCdNm : item.contrSexCdNm, // 계약자 성별
        birthDate : item.contrDob, // 계약자 생년월일
        knb: item.contrKnb, // 계약자 Knb
        knbEnc: item.contrKnbEnc, // 계약자 Knb(마스킹)
        isInsrd : false // 피보험자 여부
      }

      lv_Vm.$pmUtil.fn_OpenCustNmUiCard(lv_Vm, nmUiObject, (pData) => {
        if (pData.prcsfCd === 'I') { // 고객카드 생성시
          lv_Vm.fn_SetAsyncChnlCustId('01', pData)
        } else {
          // 네임카드 > 보유계약 클릭시, 재조회
          if (!this.$bizUtil.isEmpty(pData.custNm) && !this.$bizUtil.isEmpty(pData.chnlCustId)) {
            this.$emit('onResearch', pData)
          }
        }
      })
    },
    /**
     * @description 피보험자명을 클릭할 때 nameUI를 호출한다.
     * @param       {Object}  item 선택된 보유계약 데이터 
     */
    async fn_OpenInsrdCustInfoBs (item) {
      const lv_Vm = this

      let nmUiObject = {}

      nmUiObject = {
        cnsltNo : item.clmnyCnsltNo,   // 담당 컨설턴트
        chnlCustId : item.insrdAgtFileId, //고객채널 ID
        parentId   : 'MSPPM902M', //화면ID+
        contrInsrdSameCd : item.contrInsrdSameCd, // '01': 계피 동일, '02': 계피 상이
        custNm : item.insrdCustNm, // 피보험자 명
        custId : item.insrdCustBpId,  // 피보험자 CustId
        sxdsCd : item.insrdSexCd,     // 피보험자 성별코드
        sxdsCdNm : item.insrdSexCdNm, // 피보험자 성별
        birthDate : item.insrdDob, // 피보험자 생년월일
        knb: item.insrdKnb, // 피보험자 Knb
        knbEnc: item.insrdKnbEnc, // 피보험자 Knb(마스킹)
        isInsrd : true // 피보험자 여부
      }

      lv_Vm.$pmUtil.fn_OpenCustNmUiCard(lv_Vm, nmUiObject, (pData) => {
        if (pData.prcsfCd === 'I') { // 고객카드 생성시
          lv_Vm.fn_SetAsyncChnlCustId('02', pData)
        } else {
          // 네임카드 > 보유계약 클릭시, 재조회
          if (!this.$bizUtil.isEmpty(pData.insrdCustNm)) {
            this.$emit('onResearch', pData)
          }
        }
      })
    },
    /**
     * @description 계피동일 계약건, 채널고객ID 동기화
     * @param       {Object}  custInfo 고객카드 생성된 고객 데이터 
     */
    fn_SetAsyncChnlCustId(custTye, custInfo) {
      this.pMRetContVO.forEach(lv_Item => {
        let itemCustId = custTye === '01' ? lv_Item.contrCustBpId.substr(1) : lv_Item.insrdCustBpId.substr(1)
        if (itemCustId === custInfo.custId) {
          lv_Item.conrtAgtFileId = custInfo.chnlCustId
          lv_Item.insrdAgtFileId = custInfo.chnlCustId
        }
      })

    },
    
    /**
     * @description 보험계약기본사항(MSPPM) 화면으로 이동한다.
     *              @type viewId     수신화면, callerId   송신화면, chnlCustId  채널아이디
     *                    cnsltNo    (필수값) 컨설턴트번호, contNo     (필수값) 계약번호
     * @param       {String} contNo     계약번호
     * @param       {String} chnlCustId 채널아이디 
     */
    async fn_MoveMSPPM931M (contItem) {
      let prcContNo = ''
      
      // 계약번호 복호화 
      if(!this.$bizUtil.isEmpty(contItem.contNoEnc)) {
        prcContNo = await this.$commonUtil.getDecryptCall(this, [contItem.contNoEnc]) 
        prcContNo = prcContNo[0]
      }

      let contInfoObj = {
        viewId: null,                         
        callerId: 'MSPPM902M',     
        chnlCustId: contItem.conrtAgtFileId,               
        cnsltNo: this.cnsltNo,               
        contNo: prcContNo,  
        contObj: contItem                     
      }

      this.$router.push({name: 'MSPPM931M', params: contInfoObj})
    },

    /**
     * Function명 : fn_GetUkepStatCdNm
     * 설명       : 상태코드명 getter
     * @param {Object} item 보유계약 데이터 
     * @return {String} - 상태코드명
     */
    fn_GetUkepStatCdNm (item) {
      let t_UkepStatCdNm = ''
      if ( item.chnlUkepStatCd === '00' ) {
         t_UkepStatCdNm = '정상'
         item.ukepStatCdClassNm = 'lightblue'
      } else if ( item.chnlUkepStatCd === '03' ) {
         t_UkepStatCdNm = '면제'
         item.ukepStatCdClassNm = 'lightyellow3'
      } else if ( item.chnlUkepStatCd === '04' ) {
        t_UkepStatCdNm = '완납'
        item.ukepStatCdClassNm = 'lightgreen'
      } else if ( item.chnlUkepStatCd === '01' ) {
        t_UkepStatCdNm = '연체'
        item.ukepStatCdClassNm = 'lightred'
      } else if ( item.chnlUkepStatCd === '02' ) {
        t_UkepStatCdNm = '실효'
        item.ukepStatCdClassNm = 'lightred2'
      } else {
        t_UkepStatCdNm = item.ukepStatCd
        item.ukepStatCdClassNm = 'lightblue'
      }
      return t_UkepStatCdNm
    },

    /**
     * @description 보유계약 더보기 조회 호출한다
     */
    fn_LoadingDataCall () {
      this.$emit('on-scroll-bottom')
    },

    /**
     * @function fn_OpenSortList
     * @name     데이터정렬함수
     * @description 메인 화면에서 해당 함수 호출한다
     */
    fn_OpenSortList(sortCd){
      if (_.isEmpty(this.pMRetContVO)) return

      let type = sortCd.type
      let sort = sortCd.sort

      switch (sortCd.type) {
        case 'custNm':
          type = 'insrdCustNm'
          break;
        case 'ralDob':
          type = 'insrdDob'
          break;
      }

      if (sort === 'ASC') {
        if (!isNaN(this.pMRetContVO[0][type])) {
          return this.pMRetContVO.sort((a,b) => b[type] - a[type])
        } else {
          this.pMRetContVO.sort(function (a, b) {
            return (a[type] < b[type]) ? -1 : 1
          })
        }
      } else {
        if (!isNaN(this.pMRetContVO[0][type])) {
          return this.pMRetContVO.sort((a,b) => a[type] - b[type])
        } else {
          this.pMRetContVO.sort(function (a, b) {
            return (a[type] < b[type]) ? 1 : -1
          })
        }
      }
    },

    /**
     * @function fn_CloseNoCardTooltip
     * @name     고객카드없음_툴팁_종료
     */
    fn_CloseNoCardTooltip(key, index) {
      this.pMRetContVO[index][key] = false
      this.compKey++;
    }
  } // ::Methods End
};
</script>
<style scoped>
  .pm-txt-blue {
    color: #0100FF !important;
  }
  .list-item-wrapper, .list-item__contents {
    overflow: visible;
  }
</style>