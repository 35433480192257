/*
 * 업무구분: 계약
 * 화 면 명: MSPPM902M
 * 화면설명: 보유계약
 * 작 성 일: 2023.09.06
 * 작 성 자: sh.park
 * 
 * - 탭구조로 재개발(2023.09.06)
 */
<template>
  <ur-page-container title="보유계약" :show-title="true" type="subpage" class="msp" :action-type="actionSlot"
    @on-header-left-click="fn_ClickHeaderLeftClick"
    :topButton="true" @on-scroll="fn_OnPageScrollEvent">

    <template #frame-header-fixed>
      <!-- 탭 -->
      <mo-tab-box :defaultIdx="lv_Index" @tab-change="fn_TabChangeHandler" ref="tabbox" class="ns-move-tab-box">
        <mo-tab-content :idx=1 label="전체계약" />
        <mo-tab-content :idx=2 label="피보험자조회" />
      </mo-tab-box>
      
      <div v-if="showInsrdTooltip" class="chat-bubble type-M1 blu2" style="bottom: -41px; left: auto; right: 10px;">
        <div class="exp">계약자가 아닌<br />피보험자로만 되어있는<br />고객기준으로만 조회됩니다</div>
        <mo-icon icon="msp-close-bold" class="chat-bubble-close" @click="showInsrdTooltip = false" />
      </div>

      <!-- 총 건수, 리스트 정렬 -->
      <ur-box-container alignV="start" componentid="" direction="column" class="ns-check-sum">
        <mo-list-item>
          <div class="list-item__contents">
            <div class="list-item__contents__title fexTy3">
              <div class="fexTy1">
                <span class="sum" style="margin-top:0px;">총 <strong class="crTy-blue3">{{ fn_TotalCnt }}</strong> 명</span>
                <span class="ns-tool-tip ml5">
                  <mo-icon id="location5" icon="msp-tool-tip"></mo-icon>
                  <mo-tooltip target-id="location5" class="tool-tip-box">
                    조회기준 : D-2일기준
                  </mo-tooltip>
                </span>
              </div>

              <!-- 정렬 선택값 -->
              <msp-bottom-select ref="bottomSelect" :items="sortCdList" v-model="sortCd.value" @:itemValue="'value'" :itemText="'text'" underline class="ns-dropdown-sheet maxW130 type-1 fs14rem"
                                  placeholder="정렬 전체" bottom-title="보유계약 정렬" closeBtn/>
            </div>
          </div>
        </mo-list-item>
      </ur-box-container>
    </template>

    <!-- Content영역 -->
    <ur-box-container direction="column" alignV="start" class="msp-pm-wrap">
      <MSPPM901D ref="refPM901D" v-show="lv_Index === 1" :pMRetContVO="lv_ConrtRetContVO" :pOrgData250M="pOrgData250M" :slctTabIdx="lv_Index"
                  @on-scroll-bottom="fn_LoadingData" @onResearch="fn_Research"/>
      <MSPPM902D ref="refPM902D" v-show="lv_Index === 2" :pMRetContVO="lv_InsrdRetContVO" :pOrgData250M="pOrgData250M" :slctTabIdx="lv_Index"
                  @on-scroll-bottom="fn_LoadingData" @onResearch="fn_Research"/>
    </ur-box-container>

  </ur-page-container>
</template>
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import pmConstants from '@/config/constants/pmConstants'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'

import MSPPM902P from '@/ui/pm/MSPPM902P' // 보유계약 상세조회조건

import MSPPM901D from '@/ui/pm/MSPPM901D' // 계약자 기준
import MSPPM902D from '@/ui/pm/MSPPM902D' // 피보험자 기준
import Screen from '~systems/mixin/screen'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  // 현재 화면명
  name: 'MSPPM902M',
  // 현재 화면 ID
  screenId: 'MSPPM902M',
  mixins: [Screen],
  components: {
    MSPPM901D, // 계약자 기준 탭
    MSPPM902D  // 피보험자 기준 탭
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data () {
    return {
      lv_OrgData: {}, // 상세조회 데이터

      actionSlot:{}, // 초기화 버튼 (상단 버튼 Slot)

      lv_SearchCustNm: '',
      lv_SearchChnlCustId: '',
      lv_NmCardCustNm: '',
      lv_NmCardChnlCustId: '',

      /* 제어 변수 */
      isCreate: false, // 화면 리사이클링 실행 여부
      isSearched: false,  // 검색 실행 여부
      isInit: false, // 검색 초기화 여부
      isMngr: false,  // 지점장 여부
      isLoading: false,
      isEmptyView: false, // 데이터 없음 화면

      lv_Index: 1,      // 현재 선택된 탭 인덱스
      lv_TabTyp: 'contr', // 탭 기준(contr: 전체/insrd: 피보험자 조회화면 연결)
      checkDisabled: false, // 체크박스 선택가능여부

      lv_ReturnStr: [], // 상세조회조건 텍스트 배열
      pOrgData250M: {}, // 최근 조직 조회 데이터

      /* 통신 변수 */
      cMLnPsbCustSVO: [], // 인피니트 바인딩 리스트VO
      lv_UserInfo: {}, // 사용자정보 obj
      lv_cnsltNo: '',  // 컨설턴트 번호

      lv_RetContVO: [], // 보유계약 리스트

      lv_ConrtRetContVO: [], // 보유계약 리스트(전체)
      lv_InsrdRetContVO: [], // 보유계약 리스트(피보험자 조회화면 연결)

      lv_inputParam: { // 조회 서비스호출 Obj
        cnsltNo: '',          // 컨설턴트번호
        custNm: '',           // 고객명
        lnPrspCustScCd: '03'  // 조회구분
      },
      lv_TotalCnt: 0, // 누적 총 조회건수

      lv_ContStatCdList: [], // 계약상태코드(공통코드)

      /* 계약 정렬 선택값 */
      sortCdList: pmConstants.CONT_CONTR_SORT_CD_LIST,
      sortCd: {}, // 선택된 정렬 데이터

      // 더보기 key
      pageRowCnt: '20', // 페이지 카운트
      stndKeyList: [{stndKeyId: 'next_key', stndKeyVal: ''}, {stndKeyId: 'rowCnt', stndKeyVal: 20}], // 더보기 변수
      stndKeyVal: 'start', // 더보기 변수

      /* KeppAlive 스크롤 위치 변수 */
      contrScrollPosition: 0,
      insrdScrollPosition: 0,

      pageScrollId: 'scroll_MSPPM902M', // 화면 Scroll 영역 ID

      showInsrdTooltip: true, // 피보험자 조회화면 툴팁

      onceShowNoCardTooltip: false, // 고객카드 없음 툴팁 1회 노출여부

      mvPrevTabEvt: false, // 이전 탭으로 이동 이벤트 여부
    };
  },
  
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   * CustInfoBottomSheet에서 보유계약 진입시, KeepAlive 적용안됨                      *
   *   >> Life Cycle 실행된 후, activated 실행됨                                     *
   *   >> mounted Cycle 조회 함수 실행 조건: router 파라미터로 확인후, 실행            *
   ***********************************************************************************/
  created () {
    // post 호출 전 Progress 타입 설정.
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

    // backKey event 등록
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick)

    this.fn_Init()
  },
  mounted () {
    // 화면접속로그 등록(필수)
    this.$bizUtil.insSrnLog(this.$options.screenId)

    let rParams = this.$route.params
    if(!_.isEmpty(rParams)){
      this.getStore('pmStore').dispatch('PRE_SRN_OBJ_PM902M', {})

      this.lv_SearchCustNm = rParams.custNm
      this.lv_SearchChnlCustId = rParams.chnlCustId
    }

    /* 공통 코드 조회(UKEP_STAT_CD) */
    if (this.$bizUtil.isEmpty(rParams.custNm)) {
      this.isCreate = true
      this.fn_GetCode()
    } else {
      if (rParams.callerId === 'MSPCM270M' && rParams.auth === 'D') {
        this.fn_GetCode()
      }
    }
  },
  activated () {
    const lv_Vm = this

    // post 호출 전 Progress 타입 설정.
    window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

    if (this.pageScrollId) {
      // 스크롤 이동 처리
      setTimeout(()=>{
        if (!document.getElementById(lv_Vm.pageScrollId)) return;

        if (lv_Vm.lv_TabTyp === 'contr') { // 계약자 기준 탭 AutoScroll
          document.getElementById(lv_Vm.pageScrollId).scrollTop = lv_Vm.contrScrollPosition
        } else { // 피보험자 기준 탭 AutoScroll
          document.getElementById(lv_Vm.pageScrollId).scrollTop = lv_Vm.insrdScrollPosition
        }
      }, 500);
    }

    let rParams = this.$route.params
    if (!this.isCreate && !_.isEmpty(rParams)) {
      if (!this.$bizUtil.isEmpty(rParams.custNm) && !this.$bizUtil.isEmpty(rParams.chnlCustId)) { // 네임카드 -> 보유계약에서 접근한 경우
        this.fn_ClearPaging()
        this.lv_NmCardCustNm = rParams.custNm
        this.lv_NmCardChnlCustId = rParams.chnlCustId
        this.lv_SearchCustNm = rParams.custNm
        this.lv_SearchChnlCustId = rParams.chnlCustId

        // 탭 전체계약으로 고정
        this.lv_Index = 1
        this.lv_TabTyp = 'contr'
        this.$refs.tabbox.setIdx(this.lv_Index)

        // 상세검색 값 추가
        this.isSearched = true
        this.lv_OrgData = {
          pSearchData: {
            contrNm: rParams.custNm
          }
        }
        this.fn_SearchCont(rParams, '03')
      } else if (rParams.callerId === 'MSPCM270M' && rParams.auth === 'D') {
        this.fn_ClearPaging()
        this.lv_SearchCustNm = ''
        this.lv_SearchChnlCustId = ''
        this.fn_SearchCont()
      }
    }

    this.isCreate = false
  },
  deactivated () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick)

    // post 호출 전 Progress 타입 설정.
    window.vue.getStore('progress').dispatch('FULL')
  },
  beforeDestroy () {
    // backKey event 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.BACK_KEY, this.fn_ClickHeaderLeftClick)

    // post 호출 전 Progress 타입 설정.
    window.vue.getStore('progress').dispatch('FULL')
  },
  /***********************************************************************************
   * Watch 함수 정의 영역
   ***********************************************************************************/
  watch: {
    'sortCd.value'(value) {
      let targetRef = this.lv_TabTyp === 'contr' ? this.$refs['refPM901D'] : this.$refs['refPM902D']
      let selSortCd = null

      selSortCd = this.sortCdList.filter(item => {
        return item.value === value
      })

      targetRef.fn_OpenSortList(selSortCd[0])
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역
   ***********************************************************************************/
  computed: {
    fn_TotalCnt () {
      let rtnValue = 0

      if (this.lv_TabTyp === 'contr') {
        rtnValue = this.lv_ConrtRetContVO ? this.lv_ConrtRetContVO.length : 0
      } else {
        rtnValue = this.lv_InsrdRetContVO ? this.lv_InsrdRetContVO.length : 0
      }

      return rtnValue
    }
  },

  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /*********************************************************
     * @function fn_Init
     * @notice   화면진입시, 최초 실행 함수
     *           초기 셋팅 및 설정
     *********************************************************/
    fn_Init () {
      const lv_Vm = this

      // 상단탭
      this.actionSlot = {
        actionComponent: {
          template:'<div slot="action" class="icon-wrapper"><mo-button shape="border" class="ns-btn-round btn-title-style-1" @click="fn_Reflash">새로고침</mo-button><mo-icon type="picto" @click="fn_Search">search</mo-icon></div>',
          methods: {
            fn_Reflash () {
              lv_Vm.lv_OrgData = {}
              
              lv_Vm.fn_Research()
            },
            fn_Search () {
              lv_Vm.fn_OpenMSPPM902P()
            }
          }
        }
      }

      // router로 전달받은 고객명
      if (this.$route.params.custNm !== undefined && this.$route.params.custNm !== null && this.$route.params.custNm !== '') {
        this.lv_SearchCustNm = this.$route.params.custNm
      }
      // router로 전달받은 채널고객ID
      if (this.$route.params.chnlCustId !== undefined && this.$route.params.chnlCustId !== null && this.$route.params.chnlCustId !== '') {
        this.lv_SearchChnlCustId = this.$route.params.chnlCustId
      }

      if (this.$route.params.auth === 'D') {
        this.lv_SearchCustNm = ''
        this.lv_SearchChnlCustId = ''
      }

      // 사용자 정보
      this.lv_UserInfo = this.getStore('userInfo').getters.getUserInfo
      
      if (this.$cmUtil.isSrchOrgCombo() === 'Y') {
        this.lv_IsMngr = true
      } else {
        this.lv_IsMngr = false
      }
      
      // 정렬 데이터 설정 : 계약자 내림차순
      this.sortCd = this.$bizUtil.cloneDeep(this.sortCdList[0])
    },
    /*********************************************************
     * @function fn_ClearPaging
     * @notice   페이징 변수 초기화
     * @return {none} - 반환없음
     *********************************************************/
    fn_ClearPaging () {
      this.lv_ConrtRetContVO = [] // 데이터 초기화
      this.lv_InsrdRetContVO = [] // 데이터 초기화

      this.isSearched = false // 조회이력 기본값 setting

      // 더보기 key 변수값 초기화
      this.stndKeyList = [{stndKeyId: 'next_key', stndKeyVal: null}, {stndKeyId: 'rowCnt', stndKeyVal: 20}]
      this.stndKeyVal = 'start'
      
      // 상세조회 데이터, 정렬값 초기화
      this.lv_OrgData = {}
      let retCont = []
      if (this.lv_TabTyp === 'contr') {
        this.sortCdList = pmConstants.CONT_CONTR_SORT_CD_LIST
        retCont = this.lv_ConrtRetContVO
      } else {
        this.sortCdList = pmConstants.CONT_INSRD_SORT_CD_LIST
        retCont = this.lv_InsrdRetContVO
      }

      // 네임카드 -> 보유고객 정보 초기화
      this.lv_NmCardCustNm = ''
      this.lv_NmCardChnlCustId = ''

      retCont.splice(0)
    },
    /*********************************************************
     * @function fn_GetCode
     * @notice   계약상태 데이터 조회
     * @return {none} - 반환없음
     *********************************************************/
    fn_GetCode () {
      const lv_Vm = this

      let cdSrchList = [ // 상태코드
        {
          cdGrp: 'UKEP_STAT_CD',
          sortOption: 'ASC',
          sortColumn: 'cdcValt',
          srnId: 'MSPPM902M',
          trsntId: 'txICRE11S2'
        }
      ]

      // post 호출 전 Progress 타입 설정.
      window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

      this.$pmUtil.getCodeCombo(lv_Vm, cdSrchList)
        .then(lv_Vm.fn_GetCodeHandler)
        .catch(window.vue.error)
    },
    /*********************************************************
     * @function fn_GetCodeHandler
     * @notice   계약상태 데이터를 처리하여 화면에 반영
     * @param {Object} cdRsltList - 계약상태 데이터 객체
     * @return {none} - 반환없음
     *********************************************************/
    fn_GetCodeHandler (cdRsltList) {
      if (!_.isEmpty(cdRsltList)) {

        this.lv_ContStatCdList = cdRsltList[0].commonCodeValue.filter(item => {
          if (item.cdcValt !== '05') {
            item.value = item.cdcValt
            item.text = item.cdcValNm
          }
          return item.cdcValt !== '05'
        })

        // 보유계약리스트 조회
        this.lv_IsLoadingStatus = true

        let rParam = this.$route.params
        let srchType = _.isEmpty(rParam) || this.$bizUtil.isEmpty(rParam.custNm) ? '01' : '03'
        this.fn_SearchCont(rParam , srchType)
      }
    },
    /*********************************************************
     * @function fn_SetParams
     * @notice   보유계약 조회 서비스 호출 파라미터 셋팅
     *            srchType: '01' 전체 조회, '02' 상세조회 '03' 네임카드>보유계약
     * @param {Object} cdRsltList - 계약상태 데이터 객체
     * @return {none} - 반환없음
     *********************************************************/
    fn_SetParams (pData, srchType) {
      let isDetail = !_.isEmpty(pData) && srchType === '02'

      if (this.stndKeyVal === 'end') 
        return new Promise(function(resolve, reject) {
          console.log('No More Data');
      })

      let _cnsltNo = this.lv_UserInfo.userId
      let _orgFofNo = this.lv_UserInfo.onpstFofOrgNo

      let srchCustNm = ''
      let srchInsrdCustNm = ''

      let contInfo = {}
      let orgInfo = {}

      // 상세조회/네임카드>보유계약
      if (!_.isEmpty(pData)) {
        if (srchType === '02') { // 상세조회
          contInfo = pData.pSearchData
          orgInfo = pData.pOrgData

          srchCustNm = contInfo.contrNm
          srchInsrdCustNm = contInfo.insrdNm

          if (!_.isEmpty(orgInfo)) { // 조직정보
            _cnsltNo = orgInfo.cnslt.key
            _orgFofNo = orgInfo.fofOrg.key
          }
        } else if (srchType === '03') { // 네임카드>보유계약
          if (!pData.isInsrd) { // 계약자
            srchCustNm =  pData.custNm
          } else { // 피보험자
            srchInsrdCustNm = pData.custNm
          }
        }
      }

      let iCRetContCmpxVO = {
        custNm: srchCustNm, // 계약자명
        cnsltNo: _cnsltNo,      // _cnsltNo // 컨설턴트 사번
        orgNo: _orgFofNo, // _orgNo // 지점번호
        contStatCd: isDetail ? contInfo.contStatCd : '', // 계약상태구분코드
        contScCd: isDetail ? contInfo.customType : '',   // 모집유형
        contCnt: '0', // 계약건수
        ICContDtlCondSrchVO: {}
      }
      let ICContDtlCondSrchVO = {
        insrdNm: srchInsrdCustNm, // 피보험자명
        contStatCdNm: isDetail ? contInfo.contStatCd : '', //  계약상태코드명
        padCylCdNm:   isDetail ? contInfo.padCylCd : '',   //  납입주기코드명
        contYmd01:    isDetail ? contInfo.signDate.dateFrom.split('-').join('') : '', //  계약일자 From
        contYmd02:    isDetail ? contInfo.signDate.dateTo.split('-').join('') : '',   //  계약일자 To
        contAmt01:    isDetail ? contInfo.signPayment.payFrom : '0',             //  계약금액01
        contAmt02:    isDetail ? contInfo.signPayment.payTo : '999999999999999', //  계약금액02
        smtotPrm01:   isDetail ? contInfo.prm.prmFrom : '0',             //  합계보험료01
        smtotPrm02:   isDetail ? contInfo.prm.prmTo : '999999999999999', //  합계보험료02
        SpcAccoTypCd: isDetail ? contInfo.spcAccoType : '',     // 변액 구분
        contrSxdsCd:  isDetail ? contInfo.contrSex : '', // 계약자 성별
        insrdSxdsCd:  isDetail ? contInfo.insrdSex : '', // 피보험자 성별
        atrAplScCdNm: isDetail ? contInfo.atrDate : '',   // 자동이체일
        ukepNtsScCd:  isDetail ? contInfo.ukepNtsSc : '', // 유지횟수
        prdtNm:       isDetail ? contInfo.prdtNm : '',    // 상품명
        chnlPrdtClsfCdNm: isDetail ? contInfo.prdtClsfCd : '' //  채널상품분류코드명
      }
      iCRetContCmpxVO.ICContDtlCondSrchVO = ICContDtlCondSrchVO

      // 전체: '01' / 피보험자 조회화면 연결: '02'
      const type = this.lv_TabTyp === 'contr' ? '01' : '02' // 보유계약 리스트 조회 코드
      iCRetContCmpxVO.contrInsrdSameCd = type

      return iCRetContCmpxVO
    },
    /*********************************************************
     * @function fn_SearchCont
     * @notice   보유계약 조회 서비스
     * @param {Object} type - 계약자/피보험자 기준
     * @param {Object} pData - 상세조회 데이터
     * @param {Object} srchType - '01' 전체 조회, '02' 상세조회 '03'
     *********************************************************/
    fn_SearchCont (pData, srchType) {
      const lv_Vm = this
      if (lv_Vm.stndKeyVal === 'end') return

      let pParams = this.fn_SetParams(pData, srchType)

      const trnstId = 'txTSSPM50S1'
      const auth = 'S'
      this.post(lv_Vm, pParams, trnstId, auth)
        .then(res => {
          if (!_.isEmpty(res.body) && !_.isEmpty(res.body.pMNewRetContVO)) {
            let retContVO = res.body.pMNewRetContVO

            if (!this.$bizUtil.isEmpty(lv_Vm.lv_SearchChnlCustId)) {
              
              if (lv_Vm.lv_TabTyp === 'contr') {
                retContVO = retContVO.filter(item => {
                  return item.conrtAgtFileId === lv_Vm.lv_SearchChnlCustId
                })
              } else {
                retContVO = retContVO.filter(item => {
                  return item.insrdAgtFileId === lv_Vm.lv_SearchChnlCustId
                })
              }
            }

            retContVO.forEach(item => {
              item.mkContrDob = this.$pmUtil.getMaskingBirth(item.contrDob)
              item.mkInsrdDob = this.$pmUtil.getMaskingBirth(item.insrdDob)
              item.ukepStatCdClassNm = lv_Vm.fn_GetUkepStatCdNm(item)
            })
            
            if (lv_Vm.stndKeyVal === 'start') {
              if (lv_Vm.lv_Index === 1) {
                lv_Vm.lv_ConrtRetContVO = retContVO
              } else {
                lv_Vm.lv_InsrdRetContVO = retContVO
              }

              // 정렬 데이터 설정 : 계약자 내림차순 초기화
              lv_Vm.sortCd = this.$bizUtil.cloneDeep(lv_Vm.sortCdList[0])
            } else if (lv_Vm.stndKeyVal === 'more') {
              retContVO.forEach(item => {
                if (lv_Vm.lv_Index === 1) {
                  lv_Vm.lv_ConrtRetContVO.push(item)
                } else {
                  lv_Vm.lv_InsrdRetContVO.push(item)
                }
              })
            }

            // 전체탭 최초 고객카드 없음 고객에게 툴팁 추가
            if (!lv_Vm.onceShowNoCardTooltip && lv_Vm.lv_ConrtRetContVO.length) {
              for (const item of lv_Vm.lv_ConrtRetContVO) {
                if (this.$bizUtil.isEmpty(item.conrtAgtFileId.trim())) {
                  item.showContrCustTooltip = true
                  lv_Vm.onceShowNoCardTooltip = true
                  break;
                } else if (this.$bizUtil.isEmpty(item.insrdAgtFileId.trim())) {
                  item.showInsrdCustTooltip = true
                  lv_Vm.onceShowNoCardTooltip = true
                  break;
                }
              }
            }

            // 피보험자 조회화면 연결탭 최초 고객카드 없음 고객에게 툴팁 추가
            if (!lv_Vm.onceShowNoCardTooltip && lv_Vm.lv_InsrdRetContVO.length) {
              for (const item of lv_Vm.lv_InsrdRetContVO) {
                if (this.$bizUtil.isEmpty(item.conrtAgtFileId.trim())) {
                  item.showContrCustTooltip = true
                  lv_Vm.onceShowNoCardTooltip = true
                  break;
                } else if (this.$bizUtil.isEmpty(item.insrdAgtFileId.trim())) {
                  item.showInsrdCustTooltip = true
                  lv_Vm.onceShowNoCardTooltip = true
                  break;
                }
              }
            }
          } else {
            if (lv_Vm.lv_Index === 1) {
              lv_Vm.lv_ConrtRetContVO = []
            } else {
              lv_Vm.lv_InsrdRetContVO = []
            }
          }

          // 더보기 키값
          lv_Vm.stndKeyList = []
          if (res.trnstComm.stndKeyList[0]) {
            lv_Vm.stndKeyList.push(res.trnstComm.stndKeyList[0])

            let t_stndKeyVal = lv_Vm.stndKeyList[0].stndKeyVal
            if (this.$bizUtil.isEmpty(t_stndKeyVal)) {
              // 다음페이지 데이터 존재안함(마지막 페이지)
              lv_Vm.stndKeyVal = 'end'
            } else {
              // 다음페이지 데이터 존재함
              lv_Vm.stndKeyVal = 'more'
            }
          } else {
            lv_Vm.stndKeyVal = 'end'
          }

          // 초기화
          lv_Vm.lv_SearchCustNm = ''
          lv_Vm.lv_SearchChnlCustId = ''
        })
        .catch(error => {
          window.vue.error(error)
        })
        .finally(() => {
          lv_Vm.lv_IsLoadingStatus = false
        })
    },
    /*********************************************************
     * @function fn_ClickHeaderLeftClick
     * @notice   뒤로가기 버튼
     *********************************************************/
    fn_ClickHeaderLeftClick () {
      if (this.isSearched) {
        // 조회를 한번이라도 한 경우 전체 검색
        // 초기화
        this.lv_OrgData = {}
        this.isSearched = false // 검색이력

        this.fn_ClearPaging()

        // 재조회
        this.fn_SearchCont()
      } else {
        // 스크롤 초기화
        this.contrScrollPosition = 0
        this.insrdScrollPosition = 0

        // 조회를 하지 않았으면 뒤로가기
        if (this.$route.query?._isMenuItem) {
          this.$router.push({name: 'MSPBC002M'})
          return
        }
        this.$router.go(-1)
      }
    },
    /*********************************************************
     * @function fn_TabChangeHandler
     * @notice   전체 / 피보험자 조회화면 연결 Tab Change
     *********************************************************/
    fn_TabChangeHandler (tabIdx) {
      // 기존 탭으로 이동 이벤트 시
      if (this.mvPrevTabEvt || tabIdx === this.lv_Index) {
        this.mvPrevTabEvt = false
        return;
      }

      if (this.isSearched) {
        const popup = this.$bottomModal.open(MSPBottomAlert, {
          properties: {
            title: "",
            content: "다른 탭으로 이동 시 현재 조회조건이 초기화됩니다.<br />이동할까요?",
            title_pos_btn: "예",
            title_neg_btn: "아니요"
          },
          listeners: {
            onPopupConfirm: () => {
              this.$bottomModal.close(popup)
              this.fn_SetDataChangeTab(tabIdx)
            },
            onPopupClose: () => {
              this.$bottomModal.close(popup)

              // 기존 탭으로 이동
              this.mvPrevTabEvt = true;
              this.lv_Index = tabIdx !== 1 ? 1 : 2;
              this.$refs.tabbox.setIdx(this.lv_Index)
            }
          }
        })
      } else {
        this.fn_SetDataChangeTab(tabIdx)
      }
    },
    /*********************************************************
     * @function fn_SetDataChangeTab
     * @notice   탭 변경 시 데이터 세팅
     *********************************************************/
    fn_SetDataChangeTab(tabIdx) {
      this.lv_Index = tabIdx
      this.lv_TabTyp = tabIdx === 1 ? 'contr' : 'insrd'

      this.fn_ClearPaging()

      this.fn_SearchCont(this.lv_OrgData)
    },
    /*********************************************************
     * @function fn_LoadingData
     * @notice   데이터 추가 조회 (+페이징)
     * @return {none} - 반환없음
     *********************************************************/
    fn_LoadingData () {
      if ( this.lv_IsLoadingStatus === true ) return
      this.lv_IsLoadingStatus = true

      // post 호출 전 Progress 타입 설정.
      window.vue.getStore('progress').dispatch('PART')

      if (!this.$bizUtil.isEmpty(this.lv_NmCardCustNm) && !this.$bizUtil.isEmpty(this.lv_NmCardChnlCustId)) { // 네임카드 -> 보유계약에서 접근한 경우
        this.fn_SearchCont({ custNm: this.lv_NmCardCustNm, chnlCustId: this.lv_NmCardChnlCustId }, '03')
      } else {
        const type = this.lv_TabTyp
        this.fn_SearchCont(this.lv_OrgData, '02')
      }
    },
    /*********************************************************
     * @function fn_OpenMSPPM902P
     * @notice   상세조회조건 팝업 호출
     * @param {Object} - 설정된 상세조회조건
     * @return {Object} - 설정된 상세조회조건
     *********************************************************/
    fn_OpenMSPPM902P () {
      const lv_Vm = this
      let popupPM902P = this.$bottomModal.open(MSPPM902P, {
        properties: {
          pOrgSearchData: this.lv_OrgData, //orgSearchData, // 조직정보
          pContStatCdList: this.lv_ContStatCdList,
          isInsrd: this.lv_TabTyp === 'insrd'
        },
        listeners: {
          onPopupSearch: (pData) => {
            if (_.isEmpty(pData.rtnData)) return

            // 스크롤 초기화
            if (lv_Vm.lv_TabTyp === 'contr') { // 계약자 기준
              lv_Vm.contrScrollPosition = 0
            } else { // 피보험자 기준
              lv_Vm.insrdScrollPosition = 0
            }

            // 스크롤 이동 처리
            setTimeout(()=>{
              if (!document.getElementById(lv_Vm.pageScrollId)) return;

              if (lv_Vm.lv_TabTyp === 'contr') { // 계약자 기준 탭 AutoScroll
                document.getElementById(lv_Vm.pageScrollId).scrollTop = lv_Vm.contrScrollPosition
              } else { // 피보험자 기준 탭 AutoScroll
                document.getElementById(lv_Vm.pageScrollId).scrollTop = lv_Vm.insrdScrollPosition
              }
            }, 500);

            this.$bottomModal.close(popupPM902P) // 팝업닫기
            
            // post 호출 전 Progress 타입 설정.
            window.vue.getStore('progress').dispatch('SKEL_LIST_CHK')

            lv_Vm.fn_ClearPaging() // 초기화

            // 상세조회 데이터 setting
            lv_Vm.lv_OrgData = pData.rtnData

            lv_Vm.isSearched = true
            let pParam = pData.rtnData
            lv_Vm.fn_SearchCont(pParam, '02')
          },
          onPopupClose: () => {
            this.$bottomModal.close(popupPM902P)
          }
        }
      })
    },
    /*********************************************************
     * @function fn_OnPageScrollEvent
     * @notice   계약목록 스크롤 이벤트 메서드
     *********************************************************/
    fn_OnPageScrollEvent (e) {
      let target = e.target;      
      if(!target) return
      
      let scrollPosition = target.scrollTop 
      this.crntScrollPosition = scrollPosition

      if (this.lv_TabTyp === 'contr') {
        this.contrScrollPosition = scrollPosition
      } else {
        this.insrdScrollPosition = scrollPosition
      }
      
      // console.log("scrollPosition ="+ scrollPosition +"/ scrollHeight="+scrollHeight + " / clientHeight="+clientHeight)
    },
    /*********************************************************
     * @function fn_GetUkepStatCdNm
     * @notice   상태코드명 getter
     * @param {Object} item 보유계약 데이터 
     * @return {String} - 상태코드명
     *********************************************************/
    fn_GetUkepStatCdNm (item) {
      let t_UkepStatCdNm = ''
      let t_StatClassNm = ''
      if ( item.chnlUkepStatCd === '00' ) {
         t_UkepStatCdNm = '정상'
         item.ukepStatCdClassNm = 'lightblue'
         t_StatClassNm = 'lightblue'
      } else if ( item.chnlUkepStatCd === '03' ) {
        t_UkepStatCdNm = '면제'
         item.ukepStatCdClassNm = 'lightyellow3'
        t_StatClassNm = 'lightyellow3'
      } else if ( item.chnlUkepStatCd === '04' ) {
        t_UkepStatCdNm = '완납'
        item.ukepStatCdClassNm = 'lightgreen'
        t_StatClassNm = 'lightgreen'
      } else if ( item.chnlUkepStatCd === '01' ) {
        t_UkepStatCdNm = '연체'
        item.ukepStatCdClassNm = 'lightred'
        t_StatClassNm = 'lightred'
      } else if ( item.chnlUkepStatCd === '02' ) {
        t_UkepStatCdNm = '실효'
        item.ukepStatCdClassNm = 'lightred2'
        t_StatClassNm = 'lightred2'
      } else {
        t_UkepStatCdNm = item.ukepStatCd
        item.ukepStatCdClassNm = 'lightblue'
        t_StatClassNm = 'lightblue'
      }

      return t_StatClassNm
    },
    /*********************************************************
     * @function fn_Research
     * @notice   재조회 함수 (네임카드 > 보유계약 클릭시 호출됨)
     * @param  {Object} pData 고객정보
     * @return {String} - 상태코드명
     *********************************************************/
    fn_Research(pData) {
      // 기존 이력 초기화
      this.fn_ClearPaging()

      this.lv_SearchCustNm = !_.isEmpty(pData) ? pData.custNm : ''
      this.lv_SearchChnlCustId = !_.isEmpty(pData) ? pData.chnlCustId : ''
      this.fn_SearchCont(pData, '03')
    }
  } // ::Methods End
};
</script>

<style type="scss" scoped>
.chat-bubble.type-M1.blu2::after {
  right: 90px;
}
</style>