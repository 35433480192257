/*
 * 업무구분   : 계약관리
 * 화면 명    : MSPPM902P
 * 화면 설명  : 보유계약 상세조회조건
 */
<template>
  <ur-page-container title="보유계약 상세조건" :show-title="true" type="subpage" :id="scrollCompID">
    <ur-box-container direction="column" alignV="start">
      <ur-box-container alignV="start" componentid="ur_box_container_001" direction="column">

        <!-- 조직관련 COMPONENT : 관리자 접근시 노출 -->
        <ur-box-container direction="column" alignV="start" class="ns-sch-area">
          <SearchOrgForCM ref="refSearchOrg" v-if="lv_IsMngr" v-model="lv_OrgSearchData" :isFofType="1" :pOrgData="lv_OrgSearchData" @selectResult="fn_SlectCnsltResultPM"/>
        </ur-box-container>

        <!-- 계약자/피보험자명 -->
        <ur-box-container alignV="start" componentid="ur_box_container_002" direction="column" class="ns-input-box ns-height60">
          <mo-text-field v-model="lv_SearchCustNm" underline placeholder="계약자명을 입력하세요." clearable/>  
        </ur-box-container>

        <!-- 피보험자/계약자명 -->
        <ur-box-container alignV="start" componentid="ur_box_container_004" direction="column" class="ns-input-box ns-height60">
          <mo-text-field v-model="lv_SearchInsrdNm" underline placeholder="피보험자명을 입력하세요." clearable/>  
        </ur-box-container>

        <!-- 상품명 -->
        <ur-box-container alignV="start" componentid="ur_box_container_003" direction="column" class="ns-input-box mb45">
          <mo-text-field v-model="lv_SearchPrdtNm" underline placeholder="상품명을 입력하세요." clearable/>  
        </ur-box-container>

        <!-- 계약상태 -->
        <ur-box-container alignV="start" componentid="ur_box_container_013" direction="column" class="ns-input-box mb45">
          <span class="ns-title">계약상태</span>
          <ur-box-container alignV="start" componentid="ur_box_container_014" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary class="ns-btn-s2 n6" v-model="lv_ContStat">
              <mo-segment-button v-for="(item, idx) in lv_ContStatCdList" :value="item.value" :key="idx">{{ item.text }}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <!-- 가입상품 -->
        <ur-box-container alignV="start" componentid="ur_box_container_015" direction="column" class="ns-input-box mb45">
          <span class="ns-title">가입상품</span>
          <ur-box-container alignV="start" componentid="ur_box_container_016" direction="column" class="ns-chk-box3">
            <div class="ns-check">
              <mo-checkbox v-for="(item, idx) in lv_PrdtClsfCd" v-model="item.checked" :key="idx" @input="fn_Checkall('prdtClsf', item)">
                {{ item.text }}
              </mo-checkbox>
            </div>
          </ur-box-container>
        </ur-box-container>

        <!-- 변액구분 -->
        <ur-box-container alignV="start" componentid="ur_box_container_017" direction="column" class="ns-input-box mb45">
          <span class="ns-title">변액구분</span>
          <ur-box-container alignV="start" componentid="ur_box_container_018" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary v-model="lv_SpcAccoType">
              <mo-segment-button value=" ">전체</mo-segment-button>
              <mo-segment-button value="01">변액</mo-segment-button>
              <mo-segment-button value="02">기타</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <!-- 계약일자 -->
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-input-box mb45 msp">
          <span class="ns-title">계약일자</span>
          <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary class="ns-btn-s3 n4" v-model="lv_SignDate.value">
              <mo-segment-button value=" ">전체</mo-segment-button>
              <mo-segment-button value="1">기간</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="row" class="mt20 fexTy1 flex_nowrap">
            <mo-date-picker class="full" v-model="lv_SignDate.dateFrom" placeholder="" ref="date1" format="yyyy-MM-dd" :disabled="$bizUtil.isEmpty(lv_SignDate.value)"/>
              <span class="w35px txt-center">~</span>
            <mo-date-picker class="full" v-model="lv_SignDate.dateTo" placeholder="" ref="date2" format="yyyy-MM-dd" :disabled="$bizUtil.isEmpty(lv_SignDate.value)"/>
          </ur-box-container>          
        </ur-box-container>

        <!-- 계약금액 -->
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-input-box mb45 msp">
          <span class="ns-title">계약금액</span>
          <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary class="ns-btn-s3 n4" v-model="lv_SignPayment.value">
              <mo-segment-button value=" ">전체</mo-segment-button>
              <mo-segment-button value="1">구간</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="row" class="mt20 fexTy1 flex_nowrap">
            <mo-decimal-field v-model="lv_SignPayment.payFrom" numeric maxlength="18" virtual :disabled="$bizUtil.isEmpty(lv_SignPayment.value)"/>
            <span class="w35px txt-center">~</span>
            <mo-decimal-field v-model="lv_SignPayment.payTo" numeric maxlength="18" virtual :disabled="$bizUtil.isEmpty(lv_SignPayment.value)"/>
          </ur-box-container>
        </ur-box-container>

        <!-- 보험료 -->
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-input-box mb45 msp">
          <span class="ns-title">보험료</span>
          <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary class="ns-btn-s3 n4" v-model="lv_Prm.value">
              <mo-segment-button value=" ">전체</mo-segment-button>
              <mo-segment-button value="1">구간</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
          <ur-box-container alignV="start" componentid="" direction="row" class="mt20 fexTy1 flex_nowrap">
            <mo-decimal-field v-model="lv_Prm.prmFrom" numeric maxlength="18" virtual :disabled="$bizUtil.isEmpty(lv_Prm.value)"/>
              <span class="w35px txt-center">~</span>
            <mo-decimal-field v-model="lv_Prm.prmTo" numeric maxlength="18" virtual :disabled="$bizUtil.isEmpty(lv_Prm.value)"/>
          </ur-box-container>
        </ur-box-container>

        <!-- 모집유형 -->
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-input-box mb45">
          <span class="ns-title">모집유형</span>
          <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary class="ns-btn-s3 n4" v-model="lv_CustomType">
              <mo-segment-button v-for="(item, idx) in lv_CustomTypeList" :value="item.value" :key="idx">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <!-- 자동이체일 -->
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-input-box mb45">
          <span class="ns-title">자동이체일</span>
          <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary class="ns-btn-s3 n7" v-model="lv_AtrDate">
              <mo-segment-button v-for="(item, idx) in lv_AtrDateList" :value="item.value" :key="idx">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <!-- 유지횟수 -->
        <ur-box-container alignV="start" componentid="ur_box_container_013" direction="column" class="ns-input-box mb45">
          <span class="ns-title">유지횟수</span>
          <ur-box-container alignV="start" componentid="ur_box_container_014" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary class="ns-btn-s2 n5" v-model="lv_UkepNtsSc">
              <mo-segment-button v-for="(item, idx) in lv_UkepNtsScList" :value="item.value" :key="idx">{{item.text}}</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <!-- 납입주기 -->
        <ur-box-container alignV="start" componentid="ur_box_container_015" direction="column" class="ns-input-box mb45">
          <span class="ns-title">납입주기</span>
          <ur-box-container alignV="start" componentid="ur_box_container_016" direction="column" class="ns-chk-box3">
            <div class="ns-check">
              <mo-checkbox v-for="(item, idx) in lv_PadCylCd" v-model="item.checked" :key="idx" @input="fn_Checkall('padCylCd', item)">
                {{ item.text }}
              </mo-checkbox>
            </div>
          </ur-box-container>
        </ur-box-container>

        <!-- 계약자 성별 -->
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-input-box mb45">
          <span class="ns-title">계약자 성별</span>
          <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary class="ns-btn-s3 n3" v-model="lv_ContrSex">
              <mo-segment-button value=" ">전체</mo-segment-button>
              <mo-segment-button value="2">남자</mo-segment-button>
              <mo-segment-button value="1">여자</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <!-- 피보험자 성별 -->
        <ur-box-container alignV="start" componentid="ur_box_container_011" direction="column" class="ns-input-box mb45">
          <span class="ns-title">피보험자 성별</span>
          <ur-box-container alignV="start" componentid="ur_box_container_012" direction="column" class="ns-segment">
            <mo-segment-wrapper solid primary class="ns-btn-s3 n3" v-model="lv_InsrdSex">
              <mo-segment-button value=" ">전체</mo-segment-button>
              <mo-segment-button value="2">남자</mo-segment-button>
              <mo-segment-button value="1">여자</mo-segment-button>
            </mo-segment-wrapper>
          </ur-box-container>
        </ur-box-container>

        <ur-box-container v-if="ShowBottomBtn" alignV="start" componentid="ur_box_container_019" direction="column" class="ns-btn-relative">
          <div class="relative-div">
            <mo-button componentid="mo_button_001" color="primary" shape="border" size="medium" class="ns-btn-round white" @click="fn_ResetBtnClick">초기화</mo-button>
            <mo-button componentid="mo_button_002" color="primary" shape="border" size="medium" class="ns-btn-round blue" @click="fn_SearchBtnClick">조회</mo-button>
          </div>
        </ur-box-container>

      </ur-box-container>
    </ur-box-container>

    <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 -->
    <ur-box-container alignV="start" componentid="ur_box_container_033" direction="column">
      <div class="ns-height80"></div>
    </ur-box-container>
    <!-- 하단버튼에 의해 숨겨지는 영역만큼 추가공간 확보 //-->
  </ur-page-container>
</template>

<script>
/*******************************************************************************
 * 공통 라이브러리 INCLUDE 영역
 ******************************************************************************/
import Msg from '@/systems/webkit/msg/msg'
import moment from 'moment'
import SearchOrgForCM from '@/ui/cm/SearchOrgForCM.vue'
import pmConstants from '@/config/constants/pmConstants'
import MspBottomSelect from '@/ui/_cmm/components/MspBottomSelect.vue'
import MSPBottomAlert from '@/ui/_cmm/components/MSPBottomAlert'
import { createScrollAnimateInstance } from '@/utils/scroll/scroll-animate'

export default {
  /*****************************************************************************
   * Vue 파일 속성 정의 영역
   ****************************************************************************/
  name: 'MSPPM902P',
  screenId: 'MSPPM902P',
  components: {
    SearchOrgForCM,
    MspBottomSelect
  },
  props: {
    pOrgSearchData: {
      type: Object,
      default: {}
    },
    pContStatCdList: {
      type: Array,
      default: []
    },
    isInsrd: {
      type: Boolean,
      default: false
    }
  },
  // bottom-sheet Full Popup
  modalProps: {
    mode: 'right' // 우->좌 open popup (defalut: 하->상)
  },
  /*****************************************************************************
   * Life Cycle 함수 정의 영역
   ****************************************************************************/
  created () {
    const lv_Vm = this

    // post 호출 전 Progress 타입 설정.
    window.vue.getStore('progress').dispatch('FULL')
    
    // ************ 키패드 이벤트 등록 ************ //
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_SHOW, () => {
      // 키패드 노출시 처리
      lv_Vm.ShowBottomBtn = false
    })
    window.vue.getWkEvtHandler().$on(Msg.DEVICE.KEYBOARD_HIDE, () => {
      // 키패드 숨김시 처리
      lv_Vm.ShowBottomBtn = true
    })

    // 스크롤영역 ID 설정
    this.scrollCompID = 'scroll_' + this.$options.screenId
    // console.log('this.scrollCompID', this.scrollCompID)

    this.fn_Init()
  },
  mounted () {
    // 화면접속로그 등록
    this.$bizUtil.insSrnLog(this.$options.screenId)
    this.datepicker1 = this.$refs.date1
    this.datepicker2 = this.$refs.date2    

    // 스크롤 애니메이션 instance 선언
    let t_ElementByScrollCompID = document.getElementById(this.scrollCompID)
    this.scrollAnimateY = createScrollAnimateInstance(t_ElementByScrollCompID.getElementsByClassName('mo-page__contents-wrapper')[0], 'y')
  },
  destroyed() {
    // 키패드 이벤트 해제
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_SHOW);
    window.vue.getWkEvtHandler().$off(Msg.DEVICE.KEYBOARD_HIDE);
  },
  watch: {
    'lv_SignDate.value'(val) {
      let today = moment(new Date()).format('YYYY-MM-DD')

      if (this.$bizUtil.isEmpty(val)) {
        this.lv_SignDate.dateFrom = ''
        this.lv_SignDate.dateTo   = ''
      } else { // 기간 선택
        if (this.$bizUtil.isEmpty(this.lv_SignDate.dateFrom)) {
          this.lv_SignDate.dateFrom = today
          this.lv_SignDate.dateTo = today
        }
      }
    },
    'lv_SignPayment.value'(val) {
      if (this.$bizUtil.isEmpty(val)) {
        this.lv_SignPayment.payFrom = ''
        this.lv_SignPayment.payTo   = ''
      } else { // 구간 선택
        if (this.$bizUtil.isEmpty(this.lv_SignPayment.payFrom)) {
          this.lv_SignPayment.payFrom = '0'
          this.lv_SignPayment.payTo = '99999999999999'
        }
      }
    },
    'lv_Prm.value'(val) {
      if (this.$bizUtil.isEmpty(val)) {
        this.lv_Prm.prmFrom = ''
        this.lv_Prm.prmTo   = ''
      } else { // 구간 선택
        if (this.$bizUtil.isEmpty(this.lv_Prm.prmFrom)) {
          this.lv_Prm.prmFrom = '0'
          this.lv_Prm.prmTo = '99999999999999'
        }
      }
    }
  },
  computed: {
    fn_RtnValue() {
      const lv_Vm = this
      let rtnValues = {}

      let selPrdtClsfCd = this.fn_GetCheckList('prdtClsf') // 가입상품 체크리스트
      let selPadCylCd = this.fn_GetCheckList('padCylCd')   // 납입주기 체크리스트

      this.lv_SignPayment.payFrom = this.$bizUtil.isEmpty(this.lv_SignPayment.payFrom) ? '0' : this.lv_SignPayment.payFrom
      this.lv_SignPayment.payTo   = this.$bizUtil.isEmpty(this.lv_SignPayment.payTo) ? '99999999999999' : this.lv_SignPayment.payTo

      this.lv_Prm.prmFrom = this.$bizUtil.isEmpty(this.lv_Prm.prmFrom) ? '0' : this.lv_Prm.prmFrom
      this.lv_Prm.prmTo   = this.$bizUtil.isEmpty(this.lv_Prm.prmTo) ? '99999999999999' : this.lv_Prm.prmTo

      rtnValues.contrNm = this.lv_SearchCustNm.trim() // 계약자명
      rtnValues.insrdNm = this.lv_SearchInsrdNm.trim() // 피보험자명
      rtnValues.prdtNm = this.lv_SearchPrdtNm.trim() // 상품명
      rtnValues.contStatCd = this.lv_ContStat // 계약상태
      rtnValues.prdtClsfCd = selPrdtClsfCd // 가입상품
      rtnValues.spcAccoType = this.lv_SpcAccoType // 변액구분
      rtnValues.signDate = this.lv_SignDate // 계약일자Obj
      rtnValues.signPayment = this.lv_SignPayment // 계약금액Obj
      rtnValues.prm = this.lv_Prm // 보험료Obj
      rtnValues.customType = this.lv_CustomType // 모집유형
      rtnValues.atrDate = this.lv_AtrDate // 자동이체일
      rtnValues.ukepNtsSc = this.lv_UkepNtsSc // 유지횟수
      rtnValues.padCylCd = selPadCylCd // 납입주기
      rtnValues.contrSex = this.lv_ContrSex // 계약자 성별
      rtnValues.insrdSex = this.lv_InsrdSex // 피보험자 성별

      console.log('fn_RtnValue', rtnValues)
      return rtnValues
    }
  },
  /*****************************************************************************
   * 화면 전역 변수 선언 영역
   ****************************************************************************/
  data () {
    return {
      scrollCompID: '', // 스크롤 컴포넌트ID
      scrollAnimateY: null,

      lv_SearchCustNm: '', // 계약자명
      lv_SearchPrdtNm: '', // 상품명
      lv_SearchInsrdNm: '', // 피보험자명
      vMainCustDate: '',
      disabledDatesFrom: { 'from': new Date(), 'to': null },
      vMainCustAge: '',
      datepicker1: '',
      datepicker2: '',

      isShowCnslt: false, // 담당컨설턴트
      allChk: false, // 조직, 담당컨설턴트 조회완료 여부
      lv_OrgSearchData: {}, // 조직정보
      lv_RtnOrgSearchData: {}, // 조직정보

      lv_AlertPop: '', // bottom alert component
      lv_SpcAccoType: ' ', // 변액구분
      
      lv_SignDate: {value: ' ', dateFrom: '', dateTo: ''}, // 계약일자
      lv_SignPayment: {value: ' ', payFrom: '', payTo: ''}, // 계약금액
      lv_Prm: {value: ' ', prmFrom: '', prmTo: ''}, // 계약금액

      lv_CustomType: ' ', // 모집구분
      lv_CustomTypeList: [], // 모집구분

      lv_AtrDate: ' ', // 자동이체일
      lv_AtrDateList: [],

      lv_UkepNtsSc: ' ', // 유지횟수
      lv_UkepNtsScList: [],

      lv_PadCylCd: [], // 납입주기
      
      lv_ContrSex: ' ', // 계약자성별
      lv_InsrdSex: ' ', // 계약자성별

      ShowBottomBtn: true, // 키패드 노출시, 버튼 숨김

      lv_ContStat: ' ',
      lv_ContStatCdList: [], // 계약상태
      lv_PrdtClsfCd: [], // 가입상품

      defaultItem: {value: ' ', text: '전체'}
    }
  },
  /*****************************************************************************
   * 사용자 함수 정의 영역
   ****************************************************************************/
  methods: {
    fn_Init () {
      // 관리자여부
      this.lv_IsMngr = this.$cmUtil.isSrchOrgCombo() === 'Y'
      
      this.lv_OrgSearchData = this.pOrgSearchData.pOrgData

      // 계약상태
      this.lv_ContStatCdList = [this.defaultItem, ...this.pContStatCdList]
      
      // 가입상품
      let prdtClsfCd = [...pmConstants.PRDT_CLSF_CD]
      prdtClsfCd.forEach((item, idx) => {
        item.checked = idx !== 0 ? false : true
      });
      this.lv_PrdtClsfCd = prdtClsfCd

      // 모집구분
      let customType = pmConstants.CUSTOMER_TYPE_CD
      customType = customType.filter(item => {
        return item.value !== '01' || item.value !== '02'
      })
      this.lv_CustomTypeList = customType

      // 자동이체일
      let atrDate = this.$bizUtil.cloneDeep(pmConstants.ATR_DD_CD_LIST)
      atrDate.forEach((item, idx) => {
        if (idx !== 0) item.value = "'"+ item.value +"'"
      })
      this.lv_AtrDateList = atrDate

      // 유지횟수
      this.lv_UkepNtsScList = pmConstants.UKEP_NTS_SC_CD

      // 납입주기
      let padCylCd = [...pmConstants.PAD_CYL_CD_LIST]
      padCylCd.forEach((item, idx) => {
        item.checked = idx !== 0 ? false : true
      });
      this.lv_PadCylCd = padCylCd

      // 조회 이력 있을시, bind
      if (!_.isEmpty(this.pOrgSearchData)) {
        this.fn_DataBind(this.pOrgSearchData)
      }
    },
    // 부모화면으로부터 전달받은 파라미터를 설정
    fn_DataBind(props) {
      const lv_Vm = this

      let prop = props.pSearchData
      // 계약자명
      this.lv_SearchCustNm = prop.contrNm

      // 계약상태
      this.lv_ContStat = prop.contStatCd

      // 상품명
      this.lv_SearchPrdtNm = prop.prdtNm

      // 가입상품
      let selClsfCd = prop.prdtClsfCd.split(',')
      if (!_.isEmpty(selClsfCd)) {
        if (selClsfCd.length > 0 && selClsfCd[0] !== '') {
          lv_Vm.lv_PrdtClsfCd[0].checked = false // 전체 체크 해제
  
          lv_Vm.lv_PrdtClsfCd.forEach(item => {
              for(let i=0; i<selClsfCd.length; i++){
                  console.log(item.value.indexOf(selClsfCd[i]) > -1)
                  if (item.value.indexOf(selClsfCd[i]) > -1) {
                      item.checked = true
                  }
              }
          })

        }
      }

      // 변액구분
      this.lv_SpcAccoType = prop.spcAccoType

      // 계약일자
      this.lv_SignDate.value = prop.signDate.value
      if (prop.signDate.value === '1') {
        this.lv_SignDate.dateFrom = prop.signDate.dateFrom
        this.lv_SignDate.dateTo = prop.signDate.dateTo
      }

      // 계약금액
      this.lv_SignPayment.value = prop.signPayment.value
      if (prop.signPayment.value === '1') {
        this.lv_SignPayment.payFrom = prop.signPayment.payFrom
        this.lv_SignPayment.payTo = prop.signPayment.payTo
      }

      // 보험료
      this.lv_Prm.value = prop.prm.value
      if (prop.prm.value === '1') {
        this.lv_Prm.prmFrom = prop.prm.prmFrom
        this.lv_Prm.prmTo = prop.prm.prmTo
      }

      // 모집유형
      this.lv_CustomType = prop.customType

      // 자동이체일
      this.lv_AtrDate = prop.atrDate

      // 유지횟수
      this.lv_UkepNtsSc = prop.ukepNtsSc

      // 납입주기
      let selPadCylCd = prop.padCylCd.split(',')
      if (!_.isEmpty(selPadCylCd)) {
        if (selPadCylCd.length > 0 && selPadCylCd[0] !== '') {
          lv_Vm.lv_PadCylCd[0].checked = false // 전체 체크 해제
  
          lv_Vm.lv_PadCylCd.forEach(item => {
              for(let i=0; i<selPadCylCd.length; i++){
                  console.log(item.value.indexOf(selPadCylCd[i]) > -1)
                  if (item.value.indexOf(selPadCylCd[i]) > -1) {
                      item.checked = true
                  }
              }
          })

        }
      }

      // 계약자 성별
      this.lv_ContrSex = prop.contrSex

      // 피보험자명
      this.lv_SearchInsrdNm = prop.insrdNm

      // 피보험자 성별
      this.lv_InsrdSex = prop.insrdSex
    },
    /******************************************************************************
     * Function명 : fn_SlectCnsltResultPM()
     * 설명       : 조직 변경시, 전역변수에 셋팅
     *              담당컨설턴트로 인해 두번 셋팅을 시도하여, 마지막에 셋팅 처리
     * isShowCnslt: 조직, 담당컨설턴트까지 모두 조회후, 셋팅하였는지 여부
     * allChk: 조회버튼 활성화/비활성화 여부
     ******************************************************************************/
    fn_SlectCnsltResultPM (pData) {
      if ( this.isShowCnslt ) {
        // this.lv_OrgSearchData = pData
        this.lv_RtnOrgSearchData = pData
        this.allChk = true
      }
      this.isShowCnslt = true
    },
    
    /**
     * Function명 : fn_Checkall
     * 설명       : 상세조회 가입상품 조건에서
     *              전체 클릭시 전체가 이미 활성화 상태이면
     *              전체를 제외한 다른 모든 값을 활성화하고
     *              전체가 활성화된 상태가 아니면 전체를 제외한 나머지를 활성화
     * @param {Number} value - 1: "전체", 2: "전체" 외 나머지
     * @return {none} - 반환없음
     */
    fn_Checkall (flag, pItem) {
      let _array = []

      switch (flag) {
        case 'prdtClsf':
          _array = this.lv_PrdtClsfCd
          break;
        case 'padCylCd':
          _array = this.lv_PadCylCd
          break;
      }

      if (pItem.value.trim() === '') {
        _array.forEach((item, idx) => {
          item.checked = idx !== 0 ? !pItem.checked : pItem.checked
        })
      } else {
        let chkList = _array.filter(item => {
          return item.checked
        })

        _array[0].checked = _.isEmpty(chkList)
      }
    },

    /**
     * Function명 : fn_ResetBtnClick
     * 설명       : 상세조회 조건 초기화
     *              (상세조회 내 초기화 버튼 클릭시 호출)
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     */
    fn_ResetBtnClick (e) {
      console.log('fn_ResetBtnClick call')
      // 조직
      // this.lv_OrgSearchData = {}
      // this.lv_RtnOrgSearchData = {}
      if (this.lv_IsMngr) {
        this.$refs.refSearchOrg.fn_GetFofRol()
      }

      this.lv_SearchCustNm = '' // 계약자명
      this.lv_ContStat = ' ' // 계약상태
      this.lv_SearchPrdtNm = '' // 상품명
      this.lv_SpcAccoType = ' ' // 변액구분
      this.lv_SignDate = {value: ' ', dateFrom: '', dateTo: ''} // 계약일자Obj
      this.lv_SignPayment = {value: ' ', payFrom: '', payTo: ''} // 계약금액Obj
      this.lv_Prm = {value: ' ', prmFrom: '', prmTo: ''} // 보험료Obj
      this.lv_CustomType = ' ' // 모집유형
      this.lv_AtrDate = ' ' // 자동이체일
      this.lv_UkepNtsSc = ' ' // 유지횟수
      this.lv_ContrSex = ' ' // 계약자 성별
      this.lv_SearchInsrdNm = '' // 피보험자명
      this.lv_InsrdSex = ' ' // 피보험자 성별

      // 가입상품
      this.lv_PrdtClsfCd.forEach((item, idx) => {
        item.checked = idx !== 0 ? false : true
      });
      // 납입주기
      this.lv_PadCylCd.forEach((item, idx) => {
        item.checked = idx !== 0 ? false : true
      });
    },

    /**
     * Function명 : fn_SearchBtnClick
     * 설명       : 상세조회조건을 설정 후 부모화면에의 조회함수 호출
     * @param {MouseEvent} e - MouseEvent 이벤트
     * @return {none} - 반환없음
     */
    fn_SearchBtnClick () {
      console.log('fn_SearchBtnClick call')

      // return data set
      let t_RtnData = {
        pOrgData: this.lv_RtnOrgSearchData,
        pSearchData: this.fn_RtnValue
      }
      
      this.$emit('onPopupSearch', {rtnData: t_RtnData})
    },

    /**
     * Function명 : fn_BottomAlert
     * 설명       : 메시지를 BottomAlert으로 띄움
     * @param {String} pContent - 메시지
     * @return {none} - 반환없음
     */
    fn_BottomAlert (pContent) {
      this.lv_AlertPop = this.$bottomModal.open(MSPBottomAlert, {
        properties: {
          single: true,
          title: "알림",
          content: pContent,
          title_pos_btn: "확인"
        },
        listeners: {
          onPopupConfirm: () => {
            console.log('onPopupConfirm call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupCancel: () => {
            console.log('onPopupCancel call')
            this.$bottomModal.close(this.lv_AlertPop)
          },
          onPopupClose: () => {
            console.log('onPopupClose call')
            this.$bottomModal.close(this.lv_AlertPop)
          }
        }
      })
    },
	/**
     * 설명       : 체크박스 선택값 getting
     * @return {가입상품/납입주기 선택값 } - 반환없음
     */
    fn_GetCheckList (flag) {
      let _array = []
      let chkList = []

      switch (flag) {
        case 'prdtClsf':
          _array = this.lv_PrdtClsfCd
          break;
        case 'padCylCd':
          _array = this.lv_PadCylCd
          break;
      }

      _array.forEach(item => {
        if (item.checked && !this.$bizUtil.isEmpty(item.value)) chkList.push(item.value)
      })

      return !_.isEmpty(chkList) ? chkList.join(',') : ''
    },
  } // :: End Methods
}
</script>

<style scoped>
.ui-frame__scroll-position-top .pm902p-top-btn-display-none {
  display: none;
}
</style>
